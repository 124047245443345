<template>
  <div id="messageList">
    <ul class="listWrap">
      <li class="listInfo">
        <div class="listIcon">
 <svg t="1601177181324" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2831" width="35" height="35"><path d="M761.984 1009.28H253.696C138.368 1009.28 44.8 915.712 44.8 800.384V292.096C44.8 176.768 138.368 83.2 253.696 83.2h508.288c115.328 0 208.896 93.568 208.896 208.896v508.288c0 115.328-93.568 208.896-208.896 208.896z" fill="#FF816C" p-id="2832" data-spm-anchor-id="a313x.7781069.0.i0" class="selected"></path><path d="M507.776 265.856c-72.576 0-139.776 20.352-193.536 54.528-54.272 34.432-94.592 83.2-113.408 139.264-7.552 22.528-11.648 46.464-11.648 71.04 0 92.16 56.448 172.928 142.208 220.544 7.68 4.224 11.648 13.44 8.32 21.632-6.4 15.616-14.592 30.592-24.32 44.672-3.072 4.352 0.768 10.368 6.016 8.96 29.44-7.808 56.96-19.072 81.92-33.28 9.088-5.248 19.456-6.912 29.696-4.864 23.936 4.736 49.024 7.296 74.88 7.296 176 0 318.592-118.656 318.592-264.832 0-146.304-142.72-264.96-318.72-264.96z" fill="#FFFFFF" p-id="2833"></path></svg>
        
        </div>
        <!-- 订单通知 -->
        <div class="notification">
          <p class="fongtW">消息通知</p>
          <p>暂无通知</p>
        </div>
      </li>
      <li class="listInfo">
        <div class="listIcon">
        <svg t="1601177348372" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3622" width="35" height="35"><path d="M752.768 955.392H257.92c-112.384 0-203.392-91.008-203.392-203.392V257.152C54.528 144.768 145.536 53.76 257.92 53.76h494.848C865.152 53.76 956.16 144.768 956.16 257.152v494.848c0 112.384-91.008 203.392-203.392 203.392z" fill="#FF816C" p-id="3623" data-spm-anchor-id="a313x.7781069.0.i14" class="selected"></path><path d="M616.576 765.952H370.56c-67.968 0-123.008-55.04-123.008-123.008V396.8c0-67.968 55.04-123.008 123.008-123.008h166.272c9.984 0 18.176 8.064 18.176 18.176 0 9.984-8.064 18.176-18.176 18.176H370.56c-47.872 0-86.656 38.784-86.656 86.656v246.016c0 47.872 38.784 86.656 86.656 86.656h246.016c47.872 0 86.656-38.784 86.656-86.656V469.12c0-9.984 8.064-18.176 18.176-18.176 9.984 0 18.176 8.064 18.176 18.176v173.824c0 67.968-55.04 123.008-123.008 123.008z" fill="#FFFFFF" p-id="3624"></path><path d="M694.784 312.704m-113.408 0a113.408 113.408 0 1 0 226.816 0 113.408 113.408 0 1 0-226.816 0Z" fill="#FFFFFF" p-id="3625"></path></svg>
        </div>
        <!-- 订单通知 -->
        <div class="notification">
          <p class="fongtW">消息通知</p>
          <p>暂无通知</p>
        </div>
      </li>
      <li class="listInfo" style="border:none">
        <div class="listIcon">
          <svg t="1601177242617" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3157" width="35" height="35"><path d="M766.848 966.784H272.128c-112.256 0-203.392-91.008-203.392-203.392V268.672C68.736 156.288 159.872 65.28 272.128 65.28h494.72C879.232 65.28 970.24 156.288 970.24 268.672v494.72c0 112.256-91.008 203.392-203.392 203.392z" fill="#FF816C" p-id="3158" data-spm-anchor-id="a313x.7781069.0.i5" class="selected"></path><path d="M741.376 389.888c0-118.528-99.328-214.528-221.824-214.528-122.496 0-221.824 96-221.824 214.528 0 69.504 34.176 131.2 87.168 170.368 42.88 31.744 67.84 82.048 67.84 135.296 0 12.16 9.856 22.016 22.016 22.016h89.6c12.16 0 22.016-9.856 22.016-22.016 0-53.248 24.96-103.68 67.84-135.296 52.992-39.168 87.168-100.864 87.168-170.368zM577.792 765.568h-116.608c-8.576 0-15.488-6.912-15.488-15.488 0-8.576 6.912-15.488 15.488-15.488h116.608c8.576 0 15.488 6.912 15.488 15.488 0 8.576-6.912 15.488-15.488 15.488z" fill="#FFFFFF" p-id="3159"></path><path d="M608 434.688H431.104c-8.576 0-15.488 6.912-15.488 15.488 0 8.576 6.912 15.488 15.488 15.488h72.96v192.384c0 8.576 6.912 15.488 15.488 15.488 8.576 0 15.488-6.912 15.488-15.488V465.664h72.96c8.576 0 15.488-6.912 15.488-15.488 0-8.576-6.912-15.488-15.488-15.488z" fill="#FF816C" p-id="3160" data-spm-anchor-id="a313x.7781069.0.i7" class="selected"></path><path d="M563.584 813.568h-88.192c-8.576 0-15.488-6.912-15.488-15.488 0-8.576 6.912-15.488 15.488-15.488h88.192c8.576 0 15.488 6.912 15.488 15.488 0 8.576-6.912 15.488-15.488 15.488zM545.664 856.704h-52.224c-8.576 0-15.488-6.912-15.488-15.488 0-8.576 6.912-15.488 15.488-15.488h52.224c8.576 0 15.488 6.912 15.488 15.488 0 8.448-6.912 15.488-15.488 15.488z" fill="#FFFFFF" p-id="3161"></path></svg>
        </div>
        <!-- 订单通知 -->
        <div class="notification">
          <p class="fongtW">消息通知</p>
          <p>暂无通知</p>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less">
#messageList {
  padding: 1rem;
  width: 95%;
  margin: 1rem auto;
  background: #fff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #646464;
  .listWrap {
    width: 100%;
    .listInfo {
      font-size: 1.38rem;
      border-bottom: 1px solid #f2f2f2;
      height: 3.5rem;
      display: flex;
      align-items: center;
      padding: 1.8rem;
      .notification {
        margin-left: 1.8rem;
        line-height: 2.4rem;
        .fongtW {
          font-size: 1.46rem;
          font-weight: 500;
          color: #000;
        }
      }
    }
  }
  
}
</style>>