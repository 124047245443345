<template>
  <div id="messageCenter">
    <TopBar class="background">
      <template #leftInfo>
        <div @click="goLastIndex">
          <svg
            t="1601172699894"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4497"
            width="24"
            height="24"
          >
            <path
              d="M608.992 148.992L277.984 489.984q-8.992 8.992-8.992 21.504t8.992 22.496l331.008 340.992q8.992 8 20.992 8t20.992-8.992 8.992-20.992-8.992-20.992l-312-320 312-320q8.992-8.992 8.992-20.992t-8.992-20.992-20.992-8.992-20.992 8z"
              p-id="4498"
              fill="#ffffff"
            ></path>
          </svg>
        </div>
      </template>
      <template #middleInfo> 未读消息 </template>
    </TopBar>
    <MessageList> </MessageList>
  </div>
</template>>
<script>
import TopBar from "@/components/home/TopBar.vue";
import MessageList from "@/components/message/MessageList.vue";

export default {
  components: {
    TopBar,
    MessageList,
  },
  methods: {
    goLastIndex() {
      this.$router.go(-1)
    }
  }
};
</script>>
<style lang="less">
#messageCenter {
  margin-top: 56px;
}
</style>>